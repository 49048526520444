const modalOpen = document.querySelectorAll(".modalOpen");
const modalClose = document.querySelectorAll(".modalClose");

modalOpen.forEach((card) => {
  card.addEventListener("click", (event) => {
    const bodyID = card.dataset.id;
    const modalBody = document.getElementById(bodyID);

    modalBody.classList.remove("hidden");
  });
});

modalClose.forEach((closeBtn) => {
  closeBtn.addEventListener("click", (event) => {
    const modalBody = closeBtn.closest("div[id*='consortium-']");

    modalBody.classList.add("hidden");
  });
});

window.onload = () => {
  document.onclick = (e) => {
    if (e.target.id.includes("consortium-")) {
      const modalBodies = document.querySelectorAll("div[id*='consortium-']");

      modalBodies.forEach((modal) => {
        modal.classList.add("hidden");
      });
    }
  };

  document.onkeydown = (e) => {
    if (e.key === "Escape") {
      const modalBodies = document.querySelectorAll("div[id*='consortium-']");

      modalBodies.forEach((modal) => {
        modal.classList.add("hidden");
      });
    }
  };
};
