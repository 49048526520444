let menu = document.querySelector("#menu");

document.addEventListener("DOMContentLoaded", () => {
  menu = new Mmenu(menu, {
    offCanvas: {
      position: "left-front",
    },
    theme: "white",
    navbar: {
      title: "Menu",
    },
  });

  let arr = document.querySelectorAll(".mm-listitem");

  arr.forEach((li) => {
    li.addEventListener("click", () => {
      menu.API.close();
    });
  });
});
