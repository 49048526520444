const myScrollFunc = function () {
  const navID = document.getElementById("navbar-top");

  const y = window.scrollY;
  if (y >= 20) {
    navID.classList.remove("bg-opacity-0");
    navID.classList.add("bg-opacity-100");
    navID.classList.add("border-b-2");
    navID.classList.add("shadow-md");
  } else {
    navID.classList.remove("border-b-2");
    navID.classList.remove("bg-opacity-100");
    navID.classList.add("bg-opacity-0");
    navID.classList.remove("shadow-md");
  }
};

window.addEventListener("scroll", myScrollFunc);
